/* eslint-disable react/prop-types */
import React from 'react';
// import Highlight from 'react-highlight';
import Highlight from 'react-highlight/lib/optimized';

import GreenBullet from '../../../../assets/GreenBullet.png';
import YellowBullet from '../../../../assets/YellowBullet.png';
import PinkBullet from '../../../../assets/PinkBullet.png';

export const ProfileDetails = ({ openSection }) => (
  <section className={`api_sections_expanded ${openSection ? 'openSection openSection-profiles' : ''}`}>
    <div className="api_sections_expanded_wrapper">
      <div className="api_sections_expanded_header">
        <h4>Why Verified Profiles?</h4>
        <div className="api_sections_expanded_header_getSet">
          <div className="getSet getSet-pink">GET</div>
          <div className="getSet getSet-pink">SET</div>
        </div>
      </div>
      <p>
        Verified Profiles make it easy to add social user profiles and basic reputation using Ethereum. With profiles, users no longer need to interact with
        robotic hexadecimal addresses, but can begin to enjoy a more usable, familiar, and human web3 experience.
        <br />
        <br />
        Earth profile data is stored in a decentralized IPFS datastore that is controlled
        by the user, so data is secure and can be shared across various apps and services.
      </p>
      <div className="api_sections_expanded_usp">
        <ul>
          <li>
            <img src={PinkBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Humanize decentralized networking
            </p>
          </li>
          <li>
            <img src={PinkBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Access shared social identity and basic reputation
            </p>
          </li>
          <li>
            <img src={PinkBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Save public and encrypted profile data
            </p>
          </li>
          <li>
            <img src={PinkBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Support for shared or application specific profiles
            </p>
          </li>
          <li>
            <img src={PinkBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Compatible with Ethereum accounts (Holochain coming soon)
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export const MessagingDetails = ({ openSection }) => (
  <section className={`api_sections_expanded ${openSection ? 'openSection openSection-messaging' : ''}`}>
    <div className="api_sections_expanded_wrapper">
      <div className="api_sections_expanded_header">
        <h4>Why use Messaging?</h4>
        <div className="api_sections_expanded_header_getSet">
          <div className="getSet getSet-green">GET</div>
          <div className="getSet getSet-green">SET</div>
        </div>
      </div>
      <p>
        Messaging makes it easy to add interactivity to your campaigns with
        decentralized storage for user-generated content streams, comment threads,
        reviews, chats, and more. With threads, users can interact with each other
        on your own platform, but still retain ownership of the content they produce.
        <br />
        <br />
        Messaging data is stored in decentralized OrbitDB feedstores that are shared
        between one or more participants.
      </p>
      <div className="api_sections_expanded_usp">
        <ul>
          <li>
            <img src={GreenBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Add decentralized interactivity
            </p>
          </li>
          <li>
            <img src={GreenBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Build chat, comment, and content threads
            </p>
          </li>
          <li>
            <img src={GreenBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Support for moderation, if desired
            </p>
          </li>
          <li>
            <img src={GreenBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Compatible with Ethereum apps (Holochain coming soon)
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export const StorageDetails = ({ openSection }) => (
  <section className={`api_sections_expanded ${openSection ? 'openSection openSection-storage' : ''}`}>
    <div className="api_sections_expanded_wrapper">
      <div className="api_sections_expanded_header">
        <h4>Why Verified Actions?</h4>
        <div className="api_sections_expanded_header_getSet">
          <div className="getSet getSet-yellow">GET</div>
          <div className="getSet getSet-yellow">SET</div>
        </div>
      </div>
      <p>
        Verified Actions makes it easy to store action data and content specific to your
        campaigns directly with your user in a secure, sandboxed environment.
        With decentralized storage, you can build lighterweight
        campaigns and smart contracts and reduce data management risk, while users enjoy
        more trust in the services they use with you.
        <br />
        <br />
        Verified Actions are saved to an OrbitDB key-value datastore that is
        controlled by the user, so the user can consent to their action data being
        shared with other apps and services.
      </p>
      <div className="api_sections_expanded_usp">
        <ul>
          <li>
            <img src={YellowBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Reduce liability by storing content directly with users
            </p>
          </li>
          <li>
            <img src={YellowBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Store sensitive, action-specific, application-specific, and context-specific data
            </p>
          </li>
          <li>
            <img src={YellowBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Support for public and encrypted storage
            </p>
          </li>
          <li>
            <img src={YellowBullet} alt="bullet point" className="api_sections_expanded_usp_bullets" />
            <p>
              Compatible with Ethereum apps (Holochain coming soon)
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
);
