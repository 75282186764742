import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ProfileHover from 'profile-hover';
import Box from '3box';

import ColorCubes from '../../assets/ColorCubes.svg';
import ColorCubesMobile from '../../assets/ColorCubesMobile.svg';
import DiscordButton from './components/DiscordButton';
import Footer from './components/Footer';

import '../styles/Landing.scss';
import '../styles/NewLanding.scss';
import '../../components/styles/Nav.scss';

const styles = {
  backgroundImage: `url("${ColorCubes}")`,
  backgroundRepeat: 'absolute',
};

const graphqlQueryObject = (address) => `
{
  profile(id: "${address}") {
    name
    image
  }
}
`;

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joel: {},
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      const profileCalls = [];
      profileCalls.push(Box.profileGraphQL(graphqlQueryObject('0x358260430fc05ac0741e2f51401bf88da0050f0f'))); // joel

      const profilePromises = Promise.all(profileCalls);
      const profiles = await profilePromises;
      this.setState({
        joel: profiles[0],
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    console.log('joel', joel)
    const {
      joel
    } = this.state;

    return (
      <div className="landing_page">
        <main className="hero partners_hero">
          <div className="partners_content">
            <div className="partners_content_wrapper">
              <div className="partners_content_header">
                <h4 className="highlight_header">TEAM</h4>
              </div>
              <div className="team_mates_wrapper">
                <div className="team_mates">
                  {/* Joel */}
                  <ProfileHover
                    address="0x358260430fc05ac0741e2f51401bf88da0050f0f"
                    noTheme
                    orientation="right"
                  >
                    <a
                      href="/0x358260430fc05ac0741e2f51401bf88da0050f0f"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="team_tile">
                        {joel.profile ? <img src={`https://ipfs.infura.io/ipfs/${joel.profile.image}`} alt="profile" />
                          : <div className="team_tile_emptyImage" />}
                        <div className="team_info">
                          <h3>{joel.profile ? joel.profile.name : 'Joel Serino'}</h3>
                          <p className="team_info_role">Universe Observer</p>
                        </div>
                      </div>
                    </a>
                  </ProfileHover>

                </div>
              </div>
            </div>
          </div>
          <div className="hero_graphic">
            <div style={styles} className="hero_graphic_colorcubes-dtw" />
            <img src={ColorCubesMobile} alt="Color cubes" className="hero_graphic_colorcubes-mobile" />
          </div>
          <DiscordButton />
        </main>

        <Footer />
      </div>
    );
  }
}

Team.propTypes = {
  isLoggedIn: PropTypes.bool,
};

Team.defaultProps = {
  isLoggedIn: false,
};

const mapState = state => ({
  isLoggedIn: state.userState.isLoggedIn,
});

export default withRouter(connect(mapState)(Team));
