import land from './land/index';
import * as modal from './modal/index';
import profile from './profile/index';
import signin from './signin/index';
import spaces from './spaces/index';

export default {
  land,
  modal,
  profile,
  signin,
  spaces,
};
