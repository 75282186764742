import React from 'react';
import { Link } from 'react-router-dom';

import MailChimp from '../../../components/MailChimp';
import * as routes from '../../../utils/routes';
import ThreeBoxLogo from '../../../assets/ThreeBoxLogoWhite.svg';
import GithubIconWhite from '../../../assets/GithubIconWhite.svg';
import MediumIconWhite from '../../../assets/MediumIconWhite.svg';
import MolliePsychedelic from '../../../assets/MolliePsychedelic.png';
import Faya from '../../../assets/FayaActOfKindness.png';
import Twitter from '../../../assets/twitterWhite.svg';
import Discord from '../../../assets/discordWhite.svg';

const Footer = () => (
  <>
    <section className="join">
      <h3>
        Towards a better world for all
      </h3>
      <p>
        Join our mailing list to get on board Earth.
      </p>
      <div className="join_input">
        <MailChimp />
      </div>
      <div className="footer_line" />
    </section>
    <footer>
      <div className="footer_wrapper">
        <div className="footer_lane">
          <img src={ThreeBoxLogo} alt="Earth logo" className="footer_logo" />
        </div>
        <div className="footer_lane">
          <h5>
            LEARN MORE
          </h5>
          <Link to={routes.API_PROFILES}>
            Profiles
          </Link>
          <Link to={routes.API_MESSAGING}>
            Messaging
          </Link>
          <Link to={routes.API_STORAGE}>
            Actions
          </Link>
          <Link to={routes.HUB}>
            Universe
          </Link>
        </div>
        <div className="footer_lane">
          <h5>
            DOCS
          </h5>
          <a href="#" rel="noopener noreferrer">
            Overview
          </a>
          <a href="#" rel="noopener noreferrer">
            Web App
          </a>
          <a href="#" rel="noopener noreferrer">
            API
          </a>
        </div>
        <div className="footer_lane">
          <h5>
            MORE
          </h5>
          <a href="#" rel="noopener noreferrer">
            Inquiries
          </a>
          <Link to={routes.TEAM}>
            Team
          </Link>
          <a href="https://discord.gg/CFfGg9" target="_blank" rel="noopener noreferrer">
            Chat
          </a>
        </div>
        <div className="footer_lane rightLane">
          <div>
            <Link to={routes.HUB}>
              <button type="button">
                Sign in to Universe
              </button>
            </Link>
          </div>
          <div>
            <ul className="footer__gutter__social">
              <a href="https://github.com/thinq4yourself/universe-demo" target="_blank" rel="noopener noreferrer">
                <img src={GithubIconWhite} className="footer__socialIcons" alt="Github link" />
              </a>
              <a href="https://playmc2.org" target="_blank" rel="noopener noreferrer">
                <img src={MediumIconWhite} className="footer__socialIcons" alt="Medium link" />
              </a>
              <a href="https://playmc2.org" title="Twitter" target="_blank" rel="noopener noreferrer">
                <img src={Twitter} className="footer__socialIcons" alt="Twitter link" />
              </a>
              <a href="https://discord.gg/CFfGg9" title="Discord" target="_blank" rel="noopener noreferrer">
                <img src={Discord} className="footer__socialIcons" alt="Discord link" />
              </a>
            </ul>
            <div className="footer_privacy">
              <Link to={routes.PRIVACY}>
                Privacy
              </Link>
              <p>|</p>
              <Link to={routes.TERMS}>
                Terms
              </Link>
            </div>
            <div className="footer_copyright">
              <p>Earth Copyright 2020</p>
            </div>
          </div>
        </div>
      </div>
      <img src={Faya} alt="Faya" className="footer_mollie" />
    </footer>
  </>
);

export default Footer;